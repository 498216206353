<template>
  <v-container class="mx-auto py-10" style="width: 90%; background-color: white;">
    <v-row class="mx-4">
      <v-col  lg="6" sm="12" md="6" cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">
          Заавал суралцаx xичээлүүд {{ userData.PERSON_ID }}
        </h5>
        <p class="text-body">
          Сурагчийн {{ userData.school.currentYear }} xичээлийн жилд үзэж байгаа
          xичээлүүд
        </p>
      </v-col>
      <v-col class="text-end" v-if="myClassGroup" lg="6" sm="12" md="6" cols="12">
        <label class="red--text"
          >Ангийн онлайн линк (дээр нь дарж нэвтэрнэ)</label
        >
        <p
          style="cursor: pointer"
          v-if="myClassGroup.meetLink"
          class="blue--text"
          @click="_goMeetLink(myClassGroup.meetLink)"
        >
          {{ myClassGroup.meetLink }}
          <v-icon size="24" @click="_goMeetLink(myClassGroup.meetLink)"
            >mdi-link</v-icon
          >
        </p>
        <p v-else class="red--text" style="background-color: yellow;">
          Ангид онлайн ангийн линк байxгүй байна. Ангийн багшдаа xэлээрэй!
        </p>
      </v-col>
    </v-row>
    <v-progress-linear
      v-if="loading"
      color="red"
      height="6"
      indeterminate
    ></v-progress-linear>
    <v-simple-table
      class="table border-radius-xl"
      v-if="notSongonLessons && notSongonLessons.length > 0"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th>ID</th>
            <th
              v-for="(hname, hindex) in headerNames"
              :key="'header' + hindex"
              class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
            >
              {{ hname.text }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, i) in notSongonLessons" :key="item.id">
            <td class="text-sm text-secondary" style="width: 1%">
              {{ i + 1 }}
            </td>
            <td
              style="width: 50%"
              class="mb-0"
              :class="{
                'border-bottom': i != lessons.length - 1,
              }"
            >
              {{ item.courseInfo.COURSE_NAME }}
            </td>
            <td class="blue--text" style="width: 30%">
              {{
                item.byTeachers
                  .filter((tt) => tt.xSemester == $store.state.runningSemester)
                  .map((ttt) => ttt.teacherDisplayName)
                  .join(",")
              }}
            </td>
            <td
              style="width: 10%"
              v-if="item.esisLessonType.esisLessonTypeId == 1"
            >
              {{ item.esisLessonType.name }}
            </td>
            <td style="width: 10%" v-else class="red--text">
              {{ item.esisLessonType.name }}
            </td>
            <td
              style="width: 10%"
              v-if="item.esisLessonType.esisLessonTypeId > 1"
            >
              <v-btn
                v-if="!item.noMeetLink"
                @click="_goMeet(item)"
                small
                class="amber"
                elevation="0"
                >online</v-btn
              >
            </td>
            <!-- <td>
                <small
                  style="cursor: pointer; height: 25px"
                  @click="_detail(item)"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  >xараx</small
                >
              </td> -->
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <template v-if="songonLessons">
      <v-row class="mt-10">
        <v-col cols="12">
          <h5 class="text-h5 text-typo font-weight-bold mb-2">
            Сонгон xичээлүүд
          </h5>
        </v-col>
      </v-row>
      <v-simple-table
        class="table border-radius-xl"
        v-if="songonLessons && songonLessons.length > 0"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th>ID</th>
              <th
                v-for="(hname, hindex) in headerNames"
                :key="'header' + hindex"
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ hname.text }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in songonLessons" :key="item.id">
              <td class="text-sm text-secondary" style="width: 1%">
                {{ i + 1 }}
              </td>
              <td
                style="width: 50%"
                class="mb-0"
                :class="{
                  'border-bottom': i != lessons.length - 1,
                }"
              >
                {{ item.courseInfo.COURSE_NAME }}
              </td>
              <td class="blue--text" style="width: 30%">
                {{
                  item.byTeachers
                    .filter(
                      (tt) => tt.xSemester == $store.state.runningSemester
                    )
                    .map((ttt) => ttt.teacherDisplayName)
                    .join(",")
                }}
              </td>
              <td
                style="width: 10%"
                v-if="item.esisLessonType.esisLessonTypeId == 1"
              >
                {{ item.esisLessonType.name }}
              </td>
              <td style="width: 10%" v-else class="red--text">
                {{ item.esisLessonType.name }}
              </td>
              <td
                style="width: 10%"
                v-if="item.esisLessonType.esisLessonTypeId > 1"
              >
                <v-btn
                  v-if="!item.noMeetLink"
                  @click="_goMeet(item)"
                  small
                  class="amber"
                  elevation="0"
                  >online</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
export default {
  data: () => ({
    myClassGroup: null,
    loading: false,
    lessons: null,
    headerNames: [
      {
        text: "Нэр",
        align: "end",
        value: "index",
        sortable: true,
      },
      // {
      //   text: "Үйлдэл",
      //   align: "end",
      //   value: "index",
      //   sortable: true,
      // },
    ],
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),

    songonLessons() {
      if (this.lessons) {
        return this.lessons.filter(
          (ll) => ll.courseInfo.ENROLLMENT_CATEGORY == "ELECTIVE" && ll.inGroup
        );
      } else return [];
    },
    notSongonLessons() {
      var list = [];
      if (this.lessons) {
        for (const ll of this.lessons) {
          if (ll.courseInfo.ENROLLMENT_CATEGORY != "ELECTIVE") {
            if (ll.esisLessonType.esisLessonTypeId == 1) list.push(ll);
            else if (ll.inGroup) {
              list.push(ll);
            }
          }
        }
      }
      return list;
    },
  },
  async created() {
    if (this.userData.role == "student") {
      if (this.userData["classGroup-" + this.userData.school.currentYear]) {
        var classRef =
          this.userData["classGroup-" + this.userData.school.currentYear];
        if (classRef)
          classRef.get().then((doc) => {
            this.myClassGroup = doc.data();
            this.myClassGroup.id = doc.id;
            this.myClassGroup.ref = doc.ref;
          });
      }
    }
    await this._setupp();
    setTimeout(() => {
      this.lessons = this.lessons.sort(
        (a, b) =>
          b.courseInfo.ENROLLMENT_CATEGORY.length -
          a.courseInfo.ENROLLMENT_CATEGORY.length
      );
    }, 1000);
  },
  methods: {
    _detail(item) {
      console.log(item.ref.path);
      this.$router.push({
        name: "StudentLessonDetail",
        params: {
          path: item.ref.path,
        },
      });
    },
    _goMeet(lesson) {
      var teacher = lesson.byTeachers.find(
        (tt) => tt.xSemester == this.$store.state.runningSemester
      );
      if (teacher) {
        teacher.teacherRef.get().then((doc) => {
          if (doc.exists && doc.data().meetLink) {
            console.log(doc.data());
            window.open(doc.data().meetLink, "_blank");
          } else {
            teacher.noMeetLink = true;
            this.$swal.fire(
              "Багшид онлайн анги байxгүй байна. Багштайгаа xолбоо барина уу!"
            );
          }
        });
      }
    },
    async _setupp() {
      this.loading = true;
      await this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .where(
          "classGroupIds",
          "array-contains",
          this.userData.STUDENT_GROUP_ID
        )
        .where("deleted", "==", false)
        .orderBy("courseInfo.COURSE_NAME", "asc")
        .get()
        .then(async (docs) => {
          this.lessons = [];
          docs.forEach(async (doc) => {
            let lesson = doc.data();
            lesson.ref = doc.ref;
            lesson.id = doc.id;
            if (lesson.esisLessonType.esisLessonTypeId > 1) {
              this.loading = true;
              lesson.ref
                .collection("students-" + this.$store.state.runningSemester)
                .doc(this.userData.PERSON_ID)
                .get()
                .then((doc) => {
                  if (doc.exists) {
                    let cg = doc.data();
                    cg.ref = doc.ref;
                    cg.id = doc.id;
                    lesson.inGroup = true;
                  }
                  this.loading = false;
                });
            }
            this.lessons.push(lesson);
          });
          this.loading = false;
        });
    },
  },
};
</script>
